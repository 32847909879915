@use "@angular/material" as mat;
@import "@angular/material/theming";

$mat-estaterblue: (
  50: #e3e6f5,
  100: #c4cbe5,
  200: #949ec5,
  300: #727eab,
  400: #5a6693,
  500: #46517e,
  600: #4c5787,
  700: #34406f,
  800: #354171,
  900: #27325f,
  A100: #c4cbe5,
  A200: #949ec5,
  A400: #5a6693,
  A700: #34406f,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  ),
);

@include mat.core();

$estater-primary: mat-palette($mat-estaterblue, 900);
$estater-accent: mat-palette($mat-estaterblue, A200, A200, A200);
$estater-warn: mat-palette($mat-deep-orange);

$estater-theme: mat.define-light-theme(
  (
    color: (
      primary: $estater-primary,
      accent: $estater-accent,
      warn: $estater-warn,
    ),
  )
);

@include angular-material-theme($estater-theme);

// Form css
.gis-primary-button,
.gis-secondary-button {
  background-color: #e88a00; /* Orange background for download button */
  color: #ffffff; /* White text on orange button */
  border: 2px solid #e88a00;
  padding: 6px 20px;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  margin: 9px;
  min-width: 130px;
  &:disabled{
    background: #f5be6c;
    border: 2px solid #f5be6c;
    cursor: not-allowed;
  }
}

// Form css
.gis-secondary-button {
  // background-color: #e88a00; /* Orange background for download button */
  color: #e88a00; /* White text on orange button */
  border: 2px solid #e88a00;
  background: transparent;
}

.cursor-pointer {
  cursor: pointer;
}
#mat-mdc-slide-toggle-1-label {
  margin: 0;
  margin-left: 5px;
}

/* styles.css or styles.scss */

/* Outer space class for margins */
.outer-space {
  margin: 16px; /* Adjust margin value as needed */
}

/* Inner space class for padding */
.inner-space {
  padding: 16px; /* Adjust padding value as needed */
}

/* Variations for different levels of spacing */
.outer-space-sm {
  margin: 8px;
}

.outer-space-lg {
  margin: 24px;
}

.inner-space-sm {
  padding: 8px;
}

.inner-space-lg {
  padding: 24px;
}
.bg-none {
  background: none;
}
.border-none {
  border: none;
}
.gap-20 {
  gap: 20px;
}
