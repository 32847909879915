
/********** 2. RESET STYLES **********/
/*************************************/
@import url('https://fonts.googleapis.com/css?family=Noto+Sans');



body {
    font-size: 13px;
    color: #636E7B;
    background-color: #fff;
    font-family: 'Noto Sans', sans-serif!important;
}
.open-sans{
    font-family: 'Open Sans'!important;
}
a {
    outline: none !important;
}

a:hover,
a:active,
a:focus {
    text-decoration: none;
}
.full-screen-preview {height: 100%;    padding: 0px;    margin: 0px;    overflow: hidden;}
.full-screen-preview .mat-drawer-container {height: 100%;
background-color: #f6f6f6;}
.w-100 {width:100%;}
/*TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.*/
.mat-menu-user .mat-mdc-menu-content {
    padding:0;
}
.rounded {
    border-radius: 50%;
}
.divider {
    width: 100%;
    height: 1px;
    background: #efefef;
}
.app.fixed-header .mat-drawer-content {
    overflow: unset;
}

.middle-content {
    position: absolute;
    top: 56px;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 8px;
    min-height: calc(100% - 72px);
    touch-action: auto;
    overflow: auto!important;
}
@media (max-width: 958px) {
    .middle-content {
        padding:0;
    }
    .full-screen-preview .mat-drawer-container {height: 100%;
        background-color: #ffffff;
    }
}
.rotate-180{
    transform: rotate(180deg);
}
.table-estater {
    max-height: 450px;
    overflow: auto;
}
.table-estater mat-table {
    background-color:transparent;
}
.table-estater mat-row {
    margin:5px 0;
    background-color:white;
    padding:0;
    min-height: 0px;
    align-items: stretch;
    background-color:white;
    border:1px solid #dbddea;

}
.table-estater mat-row:hover,.table-estater mat-row.active {

    background-color:#23cb6b;
    color:white;
}

.table-estater mat-row:hover mat-cell{
    color:white;
}
.table-estater mat-header-row {
    margin:0px 0;
    padding:0;
    min-height: 40px;
    background-color:#24cb6b;
    border:1px solid #dbddea;
}

.table-estater mat-header-row mat-header-cell {
    /* background-color:white; */
    border-right:1px solid #dbddea;
}

.table-estater mat-header-row mat-header-cell:first-child {
    /* border-left:1px solid #dbddea; */
}
.table-estater mat-header-row mat-header-cell:last-child {
     border-right: none;
}

.mat-mdc-header-row {
    top: 0;
    position: sticky;
    z-index: 10;
}

.table-estater mat-header-cell {
    /* border-right:1px solid #dbddea;
    border-top:1px solid #dbddea;
    border-bottom:1px solid #dbddea; */

    padding: 15px 10px;
    color:#fff;
    font-size:14px;
    font-family: 'Noto Sans', sans-serif;
}

.table-estater mat-cell {
    padding: 2px 0px;
    color:#8c8c96;
    font-size:12px;
    font-family: 'Noto Sans', sans-serif;
    border-right:1px solid #dbddea;
}

.table-estater mat-cell:first-child {
    /* border-left:1px solid #dbddea; */
}
.table-estater mat-cell:last-child {
      border-right:none;
}


.blue-button {
    background-color: #16ceed!important;
    color:white!important;
    font-family: 'Noto Sans', sans-serif !important;
}
/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.blue-button::ng-deep .mat-button-focus-overlay {
    background-color:transparent!important;
}

.orange-button {
    background-color: #f69230!important;
    color:white!important;
    font-family: 'Noto Sans', sans-serif !important;
    min-width:171px!important;
}
/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.orange-button::ng-deep .mat-button-focus-overlay {
    background-color:transparent!important;
}

.orange-button-sm {
    background-color: #f69230!important;
    color:white!important;
    font-family: 'Noto Sans', sans-serif !important;
    min-width:171px!important;
    border:1px solid #f69230!important;
}
/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.orange-button-sm::ng-deep .mat-button-focus-overlay {
    background-color:transparent!important;
}


.trans-button-sm {
    background-color: transparent;
    color:#666c86!important;
    font-family: 'Noto Sans', sans-serif !important;
    min-width:171px!important;
    border: 1px solid #666c86!important;
}
/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.trans-button-sm::ng-deep .mat-button-focus-overlay {
    background-color:transparent!important;
}


.grey-button {
    background-color: #666c86!important;
    color:white!important;
    font-family: 'Noto Sans', sans-serif!important;
    min-width:171px!important;
    border-radius: 3px;
}
.disabled {
    background-color: #fff!important;
    border: 1px solid #ececec!important;
    font-family: 'Noto Sans', sans-serif!important;
    min-width:171px!important;
    color: #adadad!important;
}
/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.grey-button::ng-deep .mat-button-focus-overlay {
    background-color:transparent!important;
}


.ellipsisoverflow {
    text-overflow:ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.create-master-panel .mat-mdc-dialog-container {
    padding:0;
    margin:0;
    height: auto;
    overflow: inherit;
}


.create-master-panel .mat-mdc-dialog-container .mat-mdc-dialog-content {
    padding: 10px 30px;
    margin:0;
}

.bleft {
    border-right:1px solid #d7dbe6;
    padding:0 10px;
    height: inherit;
}
.cleft {
    padding:0 10px;
    height: inherit;
}


.crumb-c {
    border-bottom:1px solid #d7dbe6;
    height: 70px;
    padding: 10px 0px;
}

.v-bar {
    height: 40px;
    width:1px;
    margin:0 10px;
    border-right: 1px solid #5c658e;
}

.page-content {
    height:50px;
    background-color: #666c86;
    color:white;
}
.page-content div {
    padding:0 10px;
}

.actionbar {
    background: white;
    padding:10px 5px;
}


.greenbutton {
    background-color: #23cb6b!important;
    color:white!important;

}
.bluebutton {
    background-color: #16cded!important;
    height:50px!important;
    color:white!important;
    min-width:40px!important;
    border-radius: 0px!important;
}
.orangebutton {
    background-color: #f69230!important;
    height:50px!important;
    color:white!important;
    min-width:40px!important;
    border-radius: 0px!important;
}

.obutton {
    background-color: #f69230!important;
    color:white!important;
}

.mat-mdc-form-field {
    margin:8px 0!important;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-focused .mat-form-field-label {
    font-size:16px!important;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-label {
    font-size:16px!important;
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-label-wrapper {
    top: -1.5em!important;
    padding-top: 1.84375em!important;
}

@media screen and (max-width: 500px) and (min-width: 280px) and (orientation: portrait) {

    .orange-button {
        width:auto;
        min-width: 88px!important;
    }
    .grey-button {
        width:auto;
        min-width: 88px!important;
    }
}

.form-input {
    width: 94%;
    font-size:14px;
    padding:10px 5px;
    border:1px solid #ccc;
    font-family: 'Noto Sans', sans-serif!important;
}


.action-icon .mat-icon{
    width:18px;
}

.act-container {
    padding: 10px 10px;
}
.act-container .r-mv {
    padding-bottom:10px;
    color:#616474;
    border-bottom: 1px solid #dbdeea;
}


.mat-mdc-dialog-container .matpopup-header {
    background-color:#f69230;
    color:white;
    font-size:14px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height:40px;
}
.mat-mdc-dialog-container{

    padding: 0px!important;
}


.form-select-l {
    padding: 5px 10px;
    font-size: 12px;
    border: 1px solid #dbdeea;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 116px!important;
    outline: 0!important;
}
/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-value{
    vertical-align: middle!important;
}
/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-trigger{
    height: 100%!important
}
.form-input-l {
    height: 30px;
    min-width: 230px;
    padding-left:10px;
    outline: none;
    border:1px solid #dbdeea;
    font-size:12px;
}

.form-input-m {
    height: 30px;
    padding-left:10px;
    outline: none;
    border:1px solid #dbdeea;
    font-size:12px;
}
.form-select-m {
    padding: 5px 35px 5px 15px;
    font-size: 12px;
    border: 1px solid #dbdeea;
    height: 30px;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    appearance: none;
    background: url(/assets/images/arrowdrop.png) 96% / 8% no-repeat #ffffff;

}

.form-input-container .mat-mdc-form-field {
    margin:0!important;
}

.form-input-container .mat-mdc-checkbox {
    margin:10px 0;
}
.loader-div{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
}



/* width */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    background: transparent;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #5555;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}



/* mobile view */
@media screen and (max-width: 768px) and (min-width: 280px) and (orientation: portrait) {

/* width */
::-webkit-scrollbar {
    width: 0px !important;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent !important;
    border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: transparent !important;
    border-radius: 0px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: transparent !important;
}

}

