/* You can add global styles to this file, and also import other style files */
/*@import '~@angular/material/prebuilt-themes/deeppurple-amber.css'; */

@import 'assets/css/animate.css';
@import 'assets/plugins/bootstrap/css/bootstrap.css';
@import '../node_modules/material-design-icons/iconfont/material-icons.css';
@import 'assets/css/style.default.css';
@import '../node_modules/perfect-scrollbar/css/perfect-scrollbar.css';
/* @import '~medium-editor/dist/css/medium-editor.min.css';
@import '~medium-editor/dist/css/themes/default.min.css'; */


body {
    padding: 0;
    margin: 0;
}
.cp{
    text-transform: capitalize!important;
}
button {
  outline: none!important;
}

input {
  outline: none!important;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-arrow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid;
    margin: 0 0px;
    color: #f69230!important;
}
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-inner-container {
    height: 20px!important;
    width: 20px!important;
    position: relative;
    vertical-align: middle;
    white-space: nowrap;
    flex-shrink: 0;
    line-height: 0;
    margin: auto;
    margin-right: 8px;
    order: 0;
  }
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-frame {
    border-color: #f69230!important;
}
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #f69230!important;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-value{
    padding-left: 5px;
}
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-label{
    min-width: 100%!important;
}
.border-blue{
	border : 2px solid #16ceed !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
::ng-deep.mat-form-field-infix {
    min-height: 40px;
}
.mat-sort-header-arrow{
    color: #ffffff!important;
}
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.table-card .mat-card-header-text{
    display:none!important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
  .example-card .mat-card-header-text{
    display:none!important;
  }
  .custom-warn{
    background-color: rgb(245, 172, 36) !important;
    color:  #fff !important;
  }
 .custom-success{
    background-color: rgb(86, 196, 23) !important;
    color: #fff !important;
  }
 .custom-info{
    background-color: rgb(100, 204, 252)!important;
    color: #fff !important;
  }
   .custom-error{
    background-color:rgb(247, 86, 58) !important;
    color: #fff !important;
  }
  .mat-mdc-snack-bar-action button {
    max-height: 36px;
    min-width: 0;
    color:  #fff !important;
}
.map-border{
  stroke: #fff !important;
}
.sidebarsmall .itemlist.active .map-border, .sidebarsmall .itemlist:hover .map-border,.sidebarsmall .itemlist:active .map-border {
  stroke: #f69230 !important;
}
.pointer{
  cursor: pointer !important;
}
.pointer:disabled{
  cursor: no-drop !important;
}
textarea:focus, input:focus{
  outline: none !important;
}

.search_bar {
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  top: 0px;
  background: #fff;
  z-index: 1;
  padding: 8px 8px;
  border-bottom: 1px solid #ddd;
}

.search_bar input {
  padding: 4px 10px;
  width: 100%;
  border: 1px solid #ccc;
}

.table th, .table td {
  vertical-align: middle !important;
}
.user_name{
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 125px;
  margin: 0 10px;
}
.user-image{
  object-fit: cover;
  margin: 0 7px;
}

.mat-mdc-card-header {
  color: #fff !important;
  background-color: #27325f !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 0 10px !important;
}

.mdc-icon-button{
  font-size: 18px !important;
  width: 48px;
  height: 48px;
  padding: 0px !important;
}
.mdc-menu-surface.mat-mdc-select-panel{
  min-width: fit-content !important;
  max-width: 100%;
  padding: 0 0 !important;
}
.mat-mdc-form-field-infix{
  min-height: 40px !important;
  font-size: 14px;
}
.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix{
  padding: 10px 0!important;
}
.mdc-text-field--filled {
  background-color: transparent !important;
}
.mdc-text-field--filled:hover {
  background-color: transparent !important;
}
.mat-mdc-select{
  min-width: fit-content !important;
}
.mat-mdc-header-cell {
  font-weight: 600;
  color: #fff;
  border: 1px solid #ddd;
  padding: 8px;
  white-space: nowrap;
  font-size: 13px;
}
.mat-mdc-option .mdc-list-item__primary-text{
  white-space: nowrap !important;
}
.mat-mdc-snack-bar-container .mdc-snackbar__surface{
  background-color: transparent !important;
}
.mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action{
  color: #000 !important;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full{
  background-color: #f8a05c  !important;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal:after,.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal:after {
  color: #27325f !important;
}

.mat-mdc-checkbox.mat-accent{
  --mdc-checkbox-selected-icon-color : #f8a05c !important;
  --mdc-checkbox-selected-focus-icon-color: #f8a05c !important;
  --mdc-checkbox-selected-hover-icon-color: #f8a05c !important;
  --mdc-checkbox-selected-pressed-icon-color: #f8a05c !important;
  --mdc-checkbox-selected-checkmark-color: #fff !important;
  --mdc-checkbox-unselected-focus-icon-color: #f8a05c !important;
  --mdc-checkbox-unselected-hover-icon-color: #f8a05c !important;
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #fff !important;
  --mdc-radio-disabled-unselected-icon-color: #fff !important;
  --mdc-radio-unselected-focus-icon-color: #f8a05c !important;
  --mdc-radio-unselected-hover-icon-color: #f8a05c !important;
  --mdc-radio-selected-focus-icon-color: #f8a05c !important;
  --mdc-radio-selected-hover-icon-color: #f8a05c !important;
  --mdc-radio-selected-icon-color: #f8a05c !important;
  --mdc-radio-selected-pressed-icon-color: #f8a05c !important;
  --mat-mdc-radio-ripple-color: #fff !important;
  --mat-mdc-radio-checked-ripple-color: #f8a05c !important;
}
